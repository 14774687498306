.loading-container
{
   
    pointer-events: none;
    position:fixed;
    z-index: 999;
    width: 100vw;
    height: 100vh;
}

.loading-background
{ 
    transition:all 2.0s ease-out;
    display:flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background:rgb(21, 21, 21);
}

.loading-background.fade-out {
  opacity: 0;
}

.progress-container {
  width: 100px;
  height: 20px;
  background-color: rgba(255, 255, 255, 0.2); /* Darker background for the bar */
  border-radius: 16px;
  overflow: hidden;
  transition: opacity 1s ease-out; /* Add transition for fading out */
}

.progress-container.fade-out {
  opacity: 0;
  
}

.progress-bar {
  transition: all 0.4s ease-out;
  background: rgb(255, 255, 255);
  height: 20px;
  border-radius: 16px;
  width: 0; /* Start at 0 width */
  max-width: 100px; /* Ensure it doesn't exceed container width */
}
