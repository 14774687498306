.progress-bar {
  position: fixed;
  width: 400px;
  height: 15px;
  background-color: rgba(0, 0, 0, 0.151);
  border-radius: 5px;
}
.progress-bar2 {
  position: fixed;
  width: 400px;
  height: 15px;
  background-color: rgba(255, 255, 255, 0.151);
  border-radius: 5px;
}
.progress-bar-fill2 {
  border-radius: 5px;
  height: 15px;
  background-color: rgb(255, 255, 255);
  transition: width 0.5s ease;
}
.progress-bar-title {
  text-align: center;
  color: rgb(0, 0, 0);
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background: rgba(35, 35, 35, 0);
}

::-webkit-scrollbar-thumb {
  background-color: rgb(196, 196, 196);
  border-radius: 6px;
  border: 5px solid rgba(255, 255, 255, 0.163);
}

.progress-bar-fill {
  border-radius: 3px;
  height: 15px;
  background-color: rgb(0, 0, 0);
  transition: width 0.5s ease;
}

.bobot-horizontal {
  display: flex;
  flex-direction: row;
}
