html, body {
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
}

.render-window {}

@media screen and (min-width: 960px) {
    .render-window-project {}
}